import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { SEOWidget as SEO } from '../widgets/SEOWidget'

import { SettingYaml } from '../graphql'

interface NotFoundPageProps {
  data: {
    settingYaml: SettingYaml
  }
}
const title = '404 NOT FOUND: お探しのページは見つかりません'
const NotFoundPage: React.FC<NotFoundPageProps> = props => (
  <>
    {props && props.data && props.data.settingYaml && props.data.settingYaml.fallbackUrl && (
      <Helmet>
        <meta http-equiv="refresh" content={`1;URL=${props.data.settingYaml.fallbackUrl}`} />
      </Helmet>
    )}
    <SEO title={title} />
    <>
      <div className="faq-header">
        <h2>{title}</h2>
      </div>
      <div className="faq-panel">
        <h3 className="faq-panel-title">一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。</h3>
      </div>
    </>
  </>
)

export default NotFoundPage

export const query = graphql`
  query NotFoundData {
    settingYaml(ignore: { ne: true }) {
      fallbackUrl
    }
  }
`
